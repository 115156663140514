<template>
  <div>
    <Cycles
      id="cycle"
      :first="locale[lang].GARDIEN_V.FIRST"
      :images="images"
      :check="locale[lang].GARDIEN_V.CHECK"
      :semaine="locale[lang].GARDIEN_V.SEMAINE"
      :semaineImg="semaineImg"
      :apres="locale[lang].GARDIEN_V.APRES"
      :exercices="locale[lang].GARDIEN_V.EXERCICES"
      :infoShop="locale[lang].GARDIEN_V.INFOSHOP"
    />
  </div>
</template>

<script>
import Cycles from "@/components/Cycles.vue";
export default {
  components: {
    Cycles,
  },
  data() {
    return {
      images: [
        "Cycles/GardienV grupo 190.png",
        "Cycles/GardienV grupo 191.png",
        "Cycles/GardienV grupo 192.png",
      ],
      semaineImg: "https://da32ev14kd4yl.cloudfront.net/versioned/preparationphysiquefootball/GARDIEN3%20(2).png",
    };
  },
};
</script>
